import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePickerRecargas from '../Contratos/DatePickerRecargas';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Util from '../servicios/Util';

let nuevafecha = new Date().getTime();
let dato = new Date(nuevafecha);



class ContratoRecargaListItemTranslated extends React.Component {

    state = {
        modal: false,
    };


    componentDidMount() {

    }
    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="noticias-list">
                    <div className="recarga-list-titulo">
                        {t('RecargaList.contrato',)} {this.props.contrato.titulo}
                    </div>
                    <div className="recargas-id">
                        <b>{t('RecargaList.numContrato',)}</b> {this.props.contrato.id}
                    </div>
                    <div className="recargas-fecha">
                        <b>{t('RecargaList.fechainicio',)}</b>   <Moment format="DD/MM/YY" className="fecha">{this.props.contrato.fechainicio}</Moment>
                    </div>
                    <div className="recargas-fecha">
                        <b>{t('RecargaList.fechabaja',)}</b>   <Moment format="DD/MM/YY" className="fecha">{this.props.contrato.fecha_baja}</Moment>
                    </div>
                    <div className='btn-recargar' onClick={() => this.abrirModal()}>
                        <p className="link-pagar">
                            <FontAwesomeIcon icon="shopping-cart" className='icon' />
                            {t('RecargaList.boton',)}</p>
                    </div>
                    <Modal isOpen={this.state.modal} className="modal-recarga" >
                        <ModalHeader className="modal-header-recarga">
                            <div className="modal-cerrar-recarga">
                                <button className="modal-cerrar-recarga" onClick={this.abrirModal}>
                                    <FontAwesomeIcon icon="times" className="icon-grey" />
                                </button>
                            </div>
                            <span> {t('RecargaList.calcula',)}</span>
                        </ModalHeader>
                        <ModalBody className="">
                            <DatePickerRecargas
                                fechabaja={
                                    (() => {
                                        let date1 = new Date(Util.calcularFechaIngles(dato));
                                        let date2 = new Date(this.props.contrato.fecha_baja);
                                        if (date1 > date2)
                                            return Util.calcularFechaIngles(dato);
                                        else
                                            return this.props.contrato.fecha_baja;
                                    })()
                                }
                                tarifa={this.props.contrato.tarifa}
                                contrato={this.props.contrato.id} />
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )

    }
}
const ContratoRecargaListItem = withTranslation('common')(ContratoRecargaListItemTranslated)


function ContratoRecargaListTranslated(props) {
    const [t, i18n] = useTranslation('common');
    console.log(props);
    return (
        <div className="facturasList">
            <ul className="list-unstyled">
                {props.contrato.map(contrato => {
                    return (
                        <li key={contrato.id}>
                            <ContratoRecargaListItem contrato={contrato} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );

}
const ContratoRecargaList = withTranslation('common')(ContratoRecargaListTranslated)
export default ContratoRecargaList;
