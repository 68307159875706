import React from "react";
import "../../styles/GlobalPages.css";
import { arrowRedoOutline, personRemoveOutline } from 'ionicons/icons';
import { IonIcon, IonLabel } from '@ionic/react';
import HeaderComponent from '../../components/Index/HeaderComponent';
import { withTranslation } from "react-i18next";
import Logotipo from '../../Imagenes/Logotipo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NativeBiometric } from 'capacitor-native-biometric';
import DatosCliente from '../../components/Index/DatosCliente';
import RecuperarPass from '../../components/Index/RecuperarPass';
import Huella from '../../components/servicios/Huella';
import RecuperarPassEmail from "../../components/Index/RecuperarPassEmail";
import api from "../../components/servicios/api";
import ConfigApp from '../../components/Index/ConfigApp';
import DatosEmpresa from "../../components/Index/DatosEmpresa";
import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/react';


let tokTarjeta;

class LoginTranslated extends React.Component {

  constructor(props) {
    super(props);
    this.passwordOneRef = React.createRef();
    this.iconRevealPassword = React.createRef();
    this.state = {
      form: {
        usuario: "",
        contraseña: "",
      },
      switchLabel: 'false',
      isRevealPassword: false,
      user: null,
      codigoUser: '',
      alert: false
    };
  }


  async componentDidMount() {
    this.tokenstripre();
    //localStorage.clear();
    sessionStorage.removeItem('token');
    localStorage.removeItem('modal');
    localStorage.removeItem('token');
    localStorage.removeItem("empresa");
    localStorage.removeItem("appconf");
    localStorage.removeItem("funciones");
    const idiomaHuella = this.props.i18n.language;
    this.forceUpdate();
    const cliente = JSON.parse(localStorage.getItem('user'));
    if (cliente != null) {
      this.setState({ user: cliente.nombre, codigoUser: cliente.cif });
      const Huellasi = JSON.parse(localStorage.getItem('HuellaSi'));
      if (Huellasi == true) {
        let empresa = await DatosEmpresa.obtener(idiomaHuella);
        if (empresa == true) {
          Huella.obtener(idiomaHuella);
        }
      }
    }
    App.addListener('backButton', e => {
      if (window.location.pathname === '/' || window.location.pathname === '/Login') {
        App.exitApp();
      } else {
        window.history.back()
      }
    })
    if (isPlatform('ios')) {
      this.setState({ plataforma: false });
    } else {
      this.setState({ plataforma: true });
    }
  }

  tokenstripre = async () => {
    await api.Clientes.tokenTarjeta().then(
      ({ data }) => (tokTarjeta = data)
    );
    sessionStorage.setItem("toktarjeta", JSON.stringify(tokTarjeta));
  }


  recuperaContraseña = async () => {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    const idiomaRecupera = this.props.i18n.language;
    let datoApp = await ConfigApp.obtener(idiomaRecupera);
    if (datoApp == true) {
      const formaContrasenia = JSON.parse(localStorage.getItem('appconf'));
      if (formaContrasenia.recuperar_clave == 1) {
        if (this.state.user != null) {
          await RecuperarPass.obtener(this.state.codigoUser, idiomaRecupera);
        } else {
          await RecuperarPass.obtener(this.state.form.usuario, idiomaRecupera);
        }

      } else {
        if (this.state.user != null) {
          await RecuperarPassEmail.obtener(this.state.codigoUser, idiomaRecupera);
        } else {
          await RecuperarPassEmail.obtener(this.state.form.usuario, idiomaRecupera);
        }

      }

    } else {
      if (this.state.user != null) {
        await RecuperarPassEmail.obtener(this.state.codigoUser, idiomaRecupera);
      } else {
        await RecuperarPassEmail.obtener(this.state.form.usuario, idiomaRecupera);
      }

    }

  };

  handleChange = async (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  abrirAlert = () => {
    this.setState({ alert: !this.state.alert });
  }

  recuperaInput = async () => {
    if (this.state.switchLabel === 'true') {
      this.setState({ switchLabel: 'false' });
    }
    if (this.state.switchLabel === 'false') {
      this.setState({ switchLabel: 'true' });
    }
  }

  cambiarusuario() {
    const Huellasi = JSON.parse(localStorage.getItem('HuellaSi'));
    if (Huellasi != null) {
      NativeBiometric.deleteCredentials({
        server: process.env.REACT_APP_APP_NAME,
      }).then();

    }
    localStorage.clear();
    this.setState({ user: null, codigoUser: null });
    this.forceUpdate();
  }


  inicioSesion = async () => {
    let idioma = this.props.i18n.language;
    let token = false;
    if (this.state.switchLabel === 'true') {
      token = true;
    } else {
      token = false;
    }
    if (this.state.user != null) {
      await DatosCliente.obtener(this.state.codigoUser, this.state.form.contraseña, idioma, token);
    } else {
      await DatosCliente.obtener(this.state.form.usuario, this.state.form.contraseña, idioma, token);
    }

  };

  tooglePassword = e => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  }


  render() {
    const { t } = this.props;
    const { isRevealPassword } = this.state;

    return (
      <div className="Login">
        <div className="Login_container">
          <img className="Logotipo" src={Logotipo} alt="Logotipo" />
        </div>
        <div className="idioma-select">
          <HeaderComponent />
        </div>
        <form onSubmit={this.props.onSubmit}>
          <div className="usuario-guardado">
            {this.state.user && <span className="label">{t('Login.Bienvenido',)} {this.state.user} !</span>}
          </div>
          <div className={this.state.user ? 'hidden' : 'form-group'}>
            <IonLabel className="label">{t('Login.usuario',)}</IonLabel>
            <input
              onChange={this.handleChange}
              className="form-input"
              type="text"
              name="usuario"
              placeholder={t('Login.introduzcaUsuario',)}
            />
          </div>
          <div className="form-group2">
            <IonLabel className="label">{t('Login.contraseña',)}</IonLabel>
            <input
              onChange={this.handleChange}
              className="form-input"
              type={isRevealPassword ? "text" : "password"}
              name="contraseña"
              placeholder={t('Login.introduzcaContrasenia',)}
              ref={this.passwordOneRef}
            />
            <span onClick={this.tooglePassword} ref={this.iconRevealPassword}>
              <span>
                {isRevealPassword ?
                  <FontAwesomeIcon icon="eye" className="icon-eye" /> :
                  <FontAwesomeIcon icon="eye-slash" className="icon-eye" />
                }
              </span>
            </span>
          </div>
          <div className="form">
            <div className="switch-button">
              <input
                type="checkbox"
                name="switchLabel"
                id="switchLabel"
                onClick={() => this.recuperaInput()}
                className="switch-button__checkbox" />
              <label htmlFor="switchLabel" className="switch-button__label" onChange={this.handleChange} />
              <span className="label-RemenberPassword">
                {t('Login.guardar',)}
              </span>
            </div>
          </div>
          <div className="">
            <button
              type="button"
              onClick={() => this.inicioSesion()}
              className="btn-inicio" >
              <IonIcon className="boton-fab" icon={arrowRedoOutline} />{t('Login.entrar',)}
            </button>
          </div>
          {this.props.error && (<p className="text-danger">{this.props.error.message}</p>)}
        </form>
        <div className="pie">
          <div className="cambiar-user-div">
            <button
              type="button"
              onClick={() => this.cambiarusuario()}
              className={this.state.plataforma ? 'cambiar-user' : 'cambiar-user-ios'}>
              <IonIcon className="boton-fab" icon={personRemoveOutline} />
              {t('Login.cambioUser',)}
            </button>
          </div>
          <div className="links">
            <button
              type="button" onClick={() => this.recuperaContraseña()} className={this.state.plataforma ? 'link-recordar' : 'link-recordar-ios'}>
              <span>{t('Login.recuperar',)}</span>
            </button>
          </div>
        </div>

      </div>
    );
  }
}
const Login = withTranslation('common')(LoginTranslated)
export default Login;
