import React from 'react';
import Ticket from '../../components/Ticket/Ticket';
import Moment from 'react-moment';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import PageError from '../../components/PantallaCarga/PageError';
import api from '../../components/servicios/api';
import "../../styles/GlobalPages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import NavbarVariable from '../../components/Navbar/NavbarVariable';


let comentarios = '';

class TicketDetalleTranslated extends React.Component {

  state = {
    loading: true,
    error: false,
    datos: undefined,
    titular: '',
    sinDatos: false

  };

  componentDidMount() {
    comentarios = [];
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });
    const ticket = this.props.location.state.ticket;
    try {
      await api.Clientes.comentariosTicket(ticket).then(
        ({ data }) => (comentarios = data)
      );

      if (Array.isArray(comentarios)) {
        for (let i = 0; i < comentarios.length; i++) {
          if (comentarios[i].autor === 0) {
            comentarios[i].autor = true;
          } else {
            comentarios[i].autor = false;
          }

        };
        this.setState({ loading: false, datos: comentarios });
      } else if (comentarios == "sin datos" || comentarios === "No se han encontrado datos para el modelo <b>comentarios_ticket_app</b>") {
        this.setState({ loading: false, sinDatos: true });
      } else {
        this.setState({ loading: false, error: true });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  render() {

    const { t } = this.props;
    if (this.state.loading) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError />;
    }

    const fecha = this.props.location.state.fecha;
    const asunto = this.props.location.state.asunto;
    const ticket = this.props.location.state.ticket;
    const cliente = JSON.parse(localStorage.getItem('user'));
    if (this.state.sinDatos) {
      return (
        <div>
          <Pageinfo datos={'DetalleTicket'} />
          <div className="btn-añadir">
            <Link
              to={{
                pathname: `/Comentarios/`,
                state: {
                  ticket: `${ticket}`,
                }
              }}
              className="btn-cerrar">
              <FontAwesomeIcon icon="plus-circle" className="icon-btn" />
              <span className="texto-comentario1">{t('Button.comentario',)}</span>
            </Link>
          </div>
        </div>
      )
    }

    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Tickets'} />
        </div>
        <div className="titulo-pagina">
          <span className=""> {t('DetalleTicket.titulo',)}</span>
        </div>
        <div className="">
          <Ticket
            fecha={fecha}
            asuntoamplia={asunto}
            id={ticket}
          />
        </div>
        <div className="comentarios">
          {this.state.datos.map((dato) =>

            <div key={dato.id} className="comentarios-list">
              <div className={(dato.autor ? 'autor' : 'sin-autor')}>
                <div className="comentarios-list-autor">
                  <FontAwesomeIcon icon="user" className="icon-black" />
                  <span className="comentarios-list-autor" dangerouslySetInnerHTML={{ __html: (dato.autor ? cliente.nombre_completo : ' Servicio Técnico ') }} />
                </div>
                <div className="comentarios-list-fecha">
                  <span><Moment format="MM/YY  HH:MM" className="fecha">{dato.fecha}</Moment></span >
                </div>

              </div>
              <div className="comentarios-list-detalle">
                <span dangerouslySetInnerHTML={{ __html: (dato.asunto) }}></span>
              </div>
            </div>
          )}
        </div>
        <div className="btn-añadir">
          <Link
            to={{
              pathname: `/Comentarios/`,
              state: {
                ticket: `${ticket}`,
              }
            }}
            className="consumo">
            <FontAwesomeIcon icon="plus-circle" className="icon-btn" />
            <span className="texto-comentario">{t('Button.comentario',)}</span>
          </Link>
        </div>
        <PiePagina />
      </div>
    );
  }
}
const TicketDetalle = withTranslation('common')(TicketDetalleTranslated)
export default TicketDetalle;
