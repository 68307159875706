import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from "react-i18next";
import GraficoDonut from '../Home/GraficoDonut';
class ConsumoVacioTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        restante: '0000',
        total: '00',
        medida: 'GB',
    };


    render() {
        const { t } = this.props;
        return (
            <div className="consumo-home-global">
                <GraficoDonut
                    restante={this.state.restante}
                    total={this.state.total}
                    medida={this.state.medida}
                    color={'#fcba0c'}
                    iconos={'grey'}
                    responsive={true} />
                <div className="contenedor-iconos" id="sms">
                    <button className="icono-linea-sms">
                        <FontAwesomeIcon icon="sms" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-llama" id="llamadas">
                        <FontAwesomeIcon icon="phone-alt" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-gb" id="datos">
                        <div className={"iconos-consumo-home-dobles"} id="datos">
                            <FontAwesomeIcon icon="arrow-up" className={"iconos-gris"} id='datos' />
                            <FontAwesomeIcon icon="arrow-down" className={"iconos-gris"} id="datos" />
                        </div>
                    </button>

                </div>
            </div>
        )
    }
}

const ConsumoVacio = withTranslation('common')(ConsumoVacioTranslated)
export default ConsumoVacio;