import React from 'react';
import Contrato from '../../components/Contratos/Contrato';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import PageError from '../../components/PantallaCarga/PageError';
import "../../styles/GlobalPages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';

class ContratoDetalleTranslated extends React.Component {

  state = {
    loading: false,
    error: null,
    datos: undefined,
    consumo: true

  };

  componentDidMount() {
    const funciones = JSON.parse(localStorage.getItem('funciones'));
    if (funciones.consumo == 0) {
      this.setState({ consumo: false });
    }
  }

  render() {
    const { t } = this.props;


    if (this.state.loading) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError />;
    }

    const fecha = this.props.location.state.fecha;
    const id = this.props.location.state.id;
    let titulo = this.props.location.state.titulo;
    let precio = this.props.location.state.precio;
    const telefono = this.props.location.state.telefono;
    let precioOri = this.props.location.state.precioOri;
    if (precioOri != '') {
      precioOri = precioOri.replaceAll(',', '');
      precioOri = precioOri.replaceAll('.', ',');
    }
    precio = precio.replaceAll(',', '');
    precio = precio.replaceAll('.', ',')
    titulo = titulo.replaceAll(',', ' ')

    if (telefono == '') {
      return (
        <div className="container h-100">
          <div className="nav-navegacion">
            <NavbarVariable datos={'/Contratos'} />
          </div>
          <div className="titulo-pagina">
            <span>{t('DetalleContrato.titulo',)}</span>
          </div>
          <div>
            <Contrato
              fecha={fecha}
              titulo={titulo}
              precio={precio}
              telefono={telefono}
              id={id}
              precioOri={precioOri}
            />
          </div>
          <PiePagina />
        </div>
      );
    }


    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Contratos'} />
        </div>
        <div className="titulo-pagina">
          <span>{t('DetalleContrato.titulo',)}</span>
        </div>
        <div>
          <Contrato
            fecha={fecha}
            titulo={titulo}
            precio={precio}
            telefono={telefono}
            id={id}
            precioOri={precioOri}
          />
        </div>
        {this.state.consumo && <div className="link-consumo">
          <Link to={{ pathname: `/Consumo/` }} className="consumo">
            <FontAwesomeIcon icon="search" className="icon-btn" />
            <span className="texto-comentario">{t('Button.consumo',)}</span>
          </Link>
        </div>}
        <PiePagina />
      </div>
    );
  }
}
const ContratoDetalle = withTranslation('common')(ContratoDetalleTranslated)
export default ContratoDetalle;