import React, { useEffect } from "react";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { useTranslation, withTranslation } from "react-i18next";
import PageError from "../../components/PantallaCarga/PageError";
import { Link } from 'react-router-dom';


class ClientesPotencialesListItemTranslated extends React.Component {

    state = {
        loading: false,
        error: false,
    };

    render() {
        const { t } = this.props;
        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.loading === true) {
            return <PageLoading />;
        } else {
            return (
                <div className="listado-clientes">
                    <Link
                        to={{
                            pathname: `/Cliente/`,
                            state: {
                                codigo: `${this.props.cliente.codigo}`,
                            }
                        }}
                    >
                        <div className='facClass' >
                            <div className="num-factura">
                                <FontAwesomeIcon icon="user" className="icon-listado-clientes" />
                                <span className='btnClass'> </span>
                                <span>{/*t('Facturas.factura',)*/}</span>
                                {this.props.cliente.codigo}
                            </div>
                            <div className="fecha-factura">
                                <span>{/*t('DetalleConsumo.fecha',)*/} </span>
                                {this.props.cliente.nombrecompleto}
                            </div>
                            {this.state.loading && <MiniLoader />}
                        </div>
                    </Link>

                </div>
            );
        }
    }
}
const ClientesPotencialesListItem = withTranslation('common')(ClientesPotencialesListItemTranslated)

function useSearchClientes(clientes) {
    const [list, setList] = React.useState(clientes);


    useEffect(() => {
        const sortedList = [...list].sort(function (a, b) {
            var dateA = new Date(a.fecha).getTime();
            var dateB = new Date(b.fecha).getTime();
            return dateB > dateA ? 1 : -1;
        });
        setList(sortedList)
    }, [])
    const [query, setQuery] = React.useState("");
    const [fileteredClientes, setfileteredClientes] = React.useState(list);


    React.useMemo(() => {
        const result = list.filter((clientes) => {
            return `${clientes.codigo} ${clientes.nombrecompleto}`
                .toLowerCase()
                .includes(query.toLowerCase());
        });

        setfileteredClientes(result);
    }, [list, query]);

    return { query, setQuery, fileteredClientes };
}




function ClientesPotencialesListTranslated(props) {
    const [t, i18n] = useTranslation('common');
    const clientes = props.clientes;
    const { query, setQuery, fileteredClientes } = useSearchClientes(clientes);

    if (fileteredClientes.length === 0) {

        return (
            <div>
                <div className="div-buscar">
                    <FontAwesomeIcon icon="search" className="icon" />
                    <input
                        type="text"
                        className="form-control"
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <h5>{/*t('Facturas.info',)*/}No tiene clientes </h5>
            </div>
        );
    }

    return (
        <div className="facturasList">
            <div className="div-buscar">
                <FontAwesomeIcon icon="search" className="icon" />
                <input
                    type="text"
                    className="input-buscar"
                    placeholder={t('Contratos.buscar',)}
                    value={query}
                    onChange={e => {
                        setQuery(e.target.value);
                    }}
                />
            </div>
            <ul className="list-unstyled">

                {fileteredClientes.map(cliente => {
                    return (
                        <li key={cliente.id}>
                            <ClientesPotencialesListItem cliente={cliente} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );

}
const ClientesPotencialesList = withTranslation('common')(ClientesPotencialesListTranslated)
export default ClientesPotencialesList;
