import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { useTranslation, withTranslation } from "react-i18next";



class TicketListItemTranslated extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="list-component">
        <div className="num-factura-ticket">
          <FontAwesomeIcon icon="ticket-alt" className={this.props.ticket.resuelto ? 'icon' : 'icon-red-ticket'} />
          <span><b>{t('Ticket.numero',)}</b></span>
          {this.props.ticket.id}
        </div>
        <div className="fecha-ticket-list">
          <span><b>{t('Ticket.fecha',)}</b></span>
          <Moment format="DD/MM/YY  HH:MM" className="fecha">{this.props.ticket.fecha}</Moment>

        </div>
        <div className="asunto-list">
          <span><b>{t('Ticket.asunto',)}</b></span>
          <span dangerouslySetInnerHTML={{ __html: this.props.ticket.asuntoamplia }} />
        </div>
      </div>
    );
  }
}

const TicketListItem = withTranslation('common')(TicketListItemTranslated)


function useSearchTicket(tickets) {
  const [list, setList] = React.useState(tickets);

  useEffect(() => {
    const sortedList = [...list].sort(function (a, b) {
      var dateA = new Date(a.fecha).getTime();
      var dateB = new Date(b.fecha).getTime();
      return dateB > dateA ? 1 : -1;
    });
    setList(sortedList)
  }, [])
  const [query, setQuery] = React.useState("");
  const [filteredTickets, setfileteredTickets] = React.useState(list);


  React.useMemo(() => {
    const result = list.filter((ticket) => {
      return `${ticket.asuntoamplia} ${ticket.fecha}`
        .toLowerCase()
        .includes(query.toLowerCase());
    });

    setfileteredTickets(result);
  }, [list, query]);

  return { query, setQuery, filteredTickets };
}

function TicketListTranslated(props) {
  const tickets = props.tickets;
  const [t, i18n] = useTranslation('common');
  const { query, setQuery, filteredTickets } = useSearchTicket(tickets);

  if (filteredTickets.length === 0) {

    return (
      <div>
        <div className="div-buscar">
          <FontAwesomeIcon icon="search" className="lupa" />
          <input
            type="text"
            className="form-control"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <h5>{t('Tickets.info',)}</h5>
      </div>
    );
  }

  return (
    <div className="facturasList">
      <div className="div-buscar">
        <FontAwesomeIcon icon="search" className="lupa" />
        <input
          type="text"
          className="input-ticket"
          placeholder={t('Contratos.buscar',)}
          value={query}
          onChange={e => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <ul className="list-unstyled">

        {filteredTickets.map(ticket => {
          return (
            <li key={ticket.id}>
              <Link
                className="text-reset text-decoration-none"
                to={{
                  pathname: `/Tickets/${ticket.id}`,
                  state: {
                    fecha: `${ticket.fecha}`,
                    id: `${ticket.id}`,
                    asunto: `${ticket.asuntoamplia}`,
                    ticket: `${ticket.id}`,
                  }
                }}
              >
                <TicketListItem ticket={ticket} />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );

}

const TicketList = withTranslation('common')(TicketListTranslated)
export default TicketList;
