import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'moment-timezone';
import 'react-chartjs-2';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/styles/Global.css'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faShareSquare, faUserPlus, faEnvelope, faClock, faPowerOff, faBars, faIndustry, faBuilding, faPercent, faGlobe,
    faCalendarAlt, faUsers, faUser, faSignInAlt, faSignOutAlt, faChevronLeft, faAt, faPhoneAlt, faTags, faFile, faFileExcel,
    faMobileAlt, faKey, faCheckCircle, faTimesCircle, faFileInvoiceDollar, faSearch, faSave, faPlusCircle, faPaperPlane, faCloudUploadAlt,
    faDownload, faTicketAlt, faCircle, faChevronRight, faInfoCircle, faSearchPlus, faTv, faWifi, faSquare, faAddressBook, faFolder,
    faPencilAlt, faIdCard, faEye, faEyeSlash, faFileDownload, faShoppingCart, faTimes, faSms, faArrowUp, faArrowDown, faShare, faReply, faSimCard
} from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-regular-svg-icons';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_es from "./translations/es/common.json";
import common_ca from "./translations/ca/common.json";
import common_in from "./translations/in/common.json";
import common_pa from "./translations/pa/common.json";
//import * as serviceWorker from './serviceWorker';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

defineCustomElements(window);

library.add(fab, faShareSquare, faUserPlus, faEnvelope, faClock, faPowerOff, faBars, faIndustry, faTags, faPercent, faGlobe,
    faBuilding, faCalendarAlt, faUsers, faUser, faSignInAlt, faSignOutAlt, faChevronLeft, faAt, faAddressBook, faFolder,
    faPhoneAlt, faMobileAlt, faKey, faCheckCircle, faTimesCircle, faFileInvoiceDollar, faSearch, faSave, faPlusCircle, faCloudUploadAlt,
    faPaperPlane, faDownload, faTicketAlt, faCircle, faChevronRight, faInfoCircle, faSearchPlus, faTv, faWifi, faFile, faFileExcel,
    faSquare, faPencilAlt, faIdCard, faEye, faEyeSlash, faFileDownload, faShoppingCart, faTimes, faSms, faArrowUp, faArrowDown, faShare, faReply, faSimCard);

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'es',
    resources: {
        es: {
            common: common_es
        },
        ca: {
            common: common_ca
        },
        in: {
            common: common_in
        },
        pa: {
            common: common_pa
        },
    },
});

ReactDOM.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </BrowserRouter>,

    document.getElementById('root'));

//serviceWorkerRegistration.register();
