import React, { useEffect } from "react";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { useTranslation, withTranslation } from "react-i18next";
import PageError from "../../components/PantallaCarga/PageError";



class AgendasListItemTranslated extends React.Component {

    state = {
        loading: false,
        error: false,
    };



    componentDidMount() {
    }

    render() {
        const { t } = this.props;

        if (this.state.error) {
            return <PageError />;
        }

        if (this.state.loading === true) {
            return <PageLoading />;
        } else {
            return (
                <div>
                    <div className=''>
                        <div className="div-contacto-nombre">
                            <FontAwesomeIcon icon="user" className='icono-agenda' />
                            <span className='nombre-contacto'>  {t('Agenda.nombre',)}</span>
                            <span> {this.props.contacto.usuario}</span>
                        </div>
                        <div className='div-numero-contacto'>
                            <span className='numero-contacto'>{t('Agenda.numero',)}</span>
                            <span>{this.props.contacto.numero}</span>
                        </div>
                        <div className="div-email-contacto">
                            <span className='numero-contacto'>{t('Agenda.email',)} </span>
                            <span>{this.props.contacto.email}</span>
                        </div>

                        {this.state.loading && <MiniLoader />}
                    </div>

                </div>
            );
        }
    }
}
const AgendasListItem = withTranslation('common')(AgendasListItemTranslated)

function useSearchContactos(Contactos) {
    const [list, setList] = React.useState(Contactos);

    const [query, setQuery] = React.useState("");
    const [fileteredContactos, setfileteredContactos] = React.useState(list);


    React.useMemo(() => {
        const result = list.filter((contacto) => {
            return `${contacto.nombre} ${contacto.numero}`
                .toLowerCase()
                .includes(query.toLowerCase());
        });

        setfileteredContactos(result);
    }, [list, query]);

    return { query, setQuery, fileteredContactos };
}




function AgendasListTranslated(props) {
    const [t, i18n] = useTranslation('common');
    const id = props.id;
    const Contactos = props.contactos;
    const { query, setQuery, fileteredContactos } = useSearchContactos(Contactos);

    if (fileteredContactos.length === 0) {

        return (
            <div>
                <div className="div-buscar">
                    <FontAwesomeIcon icon="search" className="icon" />
                    <input
                        type="text"
                        className="form-control"
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <h5>{t('Agenda.info',)} </h5>
            </div>
        );
    }

    return (
        <div className="agendaList">
            <div className="div-buscar-agenda">
                <FontAwesomeIcon icon="search" className="icon" />
                <input
                    type="text"
                    className="input-buscar-agenda"
                    placeholder={t('Contratos.buscar',)}
                    value={query}
                    onChange={e => {
                        setQuery(e.target.value);
                    }}
                />
            </div>
            <ul className="list-unstyled-agenda">
                {fileteredContactos.map((contacto) => {
                    if (contacto.id_agenda == id) {
                        return <li key={contacto.id}>
                            <AgendasListItem contacto={contacto} />
                        </li>
                    }
                })}
            </ul>
        </div>
    );

}
const AgendasList = withTranslation('common')(AgendasListTranslated)
export default AgendasList;
