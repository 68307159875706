import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation, withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import api from '../../components/servicios/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Util from '../servicios/Util';
import { Link } from 'react-router-dom';
registerLocale('es', es);
setDefaultLocale('es', es);
let checkmarcado = false;
let respuesta;
let mesesVar = 1;
let userData;
function DatePickerRecargasTranslated(props) {
    const [t, i18n] = useTranslation('common');
    const [startDate, setStartDate] = useState(Util.fechaLarga(props.fechabaja));
    const [endDate, setEndDate] = useState(Util.addDays(Util.fechaLarga(props.fechabaja)));
    const [precio, setPrecio] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [advertencia, setAdvertencia] = useState(true);
    const [CheckMes, setCheckMes] = useState(false);
    const [Meses, setMeses] = useState(0);
    const [diasGracia, setDiasGracia] = useState(0);

    useEffect(() => {

        let inicio = Util.calcularFecha(startDate);
        let fin = Util.calcularFecha(endDate);
        calculadias(props.contrato);
        if (startDate > endDate) {
            setAdvertencia(false)
            setDisabled(true);
        } else if (inicio == fin) {
            setAdvertencia(false)
            setDisabled(true);
        } else {
            setAdvertencia(true)
            setDisabled(false);
            if (Meses == 0) {
                setMeses(1);
            }
            calcular(props.tarifa, inicio, fin, props.contrato, checkmarcado, Meses);

        }
        async function calculadias(contrato) {
            try {
                await api.Clientes.diasGracia(contrato).then(
                    ({ data }) => (userData = data)
                );

                setDiasGracia(userData);
            } catch (error) {
                this.setState({ loading: false, error: error });
            }
        }
        async function calcular(tarifa, fechainicio, fechafin, contrato, check, meses) {
            try {
                const cliente = JSON.parse(localStorage.getItem('user'));
                await api.Clientes.calcularPrecio(tarifa, fechainicio, fechafin, cliente.usuario, contrato, check, meses).then(
                    ({ data }) => (respuesta = data)
                );
                setPrecio(respuesta);
            } catch (error) {
                this.setState({ loading: false, error: error });
            }

        };

    }, [startDate, endDate]);

    const handleOnChange = event => {
        if (event.target.name == 'meses') {
            setMeses(event.target.value);
            mesesVar = event.target.value;
        }
        if (event.target.name == 'mescompleto') {
            if (CheckMes == false) {
                checkmarcado = true;
                setCheckMes(!CheckMes);
            } else {
                setCheckMes(!CheckMes);
                checkmarcado = false;
            }

        }
        let inicio = Util.calcularFecha(startDate);
        let fin = Util.calcularFecha(endDate);
        calcular2(props.tarifa, inicio, fin, props.contrato, checkmarcado, mesesVar);
    };

    async function calcular2(tarifa, fechainicio, fechafin, contrato, check, meses) {
        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.calcularPrecio(tarifa, fechainicio, fechafin, cliente.usuario, contrato, check, meses).then(
                ({ data }) => (respuesta = data)
            );
            setPrecio(respuesta);
        } catch (error) {
            this.setState({ loading: false, error: error });
        }

    };


    return (
        <div>
            <div className="fechainicio">
                <span className="fechas-texto">
                    {t('RecargaList.fechainicio',)}
                </span>
                <DatePicker locale="es" selected={startDate} dateFormat="dd/MM/yyyy" minDate={Util.fechaLarga(props.fechabaja)} onChange={date => setStartDate(date)} />
            </div>
            {checkmarcado == false ?
                <div className="fechainicio">
                    <span className="fechas-texto-fin">
                        {t('RecargaList.fechafin',)}
                    </span>
                    <DatePicker locale="es" selected={endDate} dateFormat="dd/MM/yyyy" minDate={Util.addDaysGracia((Util.fechaLarga(props.fechabaja)), diasGracia)} onChange={date => setEndDate(date)} />
                </div> : ''}
            <div className={advertencia ? 'hidden' : ''}>
                <span className="info-recarga">{t('RecargaList.infofecha',)}</span>
            </div>
            <div className="mescompleto">
                <label >Mes Completo  </label>
                <input type="checkbox" id="mescompleto" name="mescompleto" className="mes_completo_input" value={'ll'} checked={CheckMes} onChange={handleOnChange} />
            </div>
            <div className="mescompleto">
                <label>Meses</label>
                <input type="number" id="mescompleto" name="meses" className="mes_completo_input" min="10" max="100" onChange={handleOnChange} />
            </div>
            <div>
                {diasGracia > 0 ?
                    <div>
                        <span>{t('RecargaList.tiene',)} {diasGracia} {t('RecargaList.dias',)}</span>
                    </div>
                    : ''
                }
            </div>
            <div className="importe-recarga">
                <span>{t('RecargaList.importe',)} </span>
                {precio}
                <span> €</span>
            </div>
            <br></br>

            <div className={disabled ? 'hidden' : ''}>
                <Link to={{
                    pathname: `/FormasPago/`,
                    state: {
                        factura: props.contrato,
                        importe: precio,
                        contrato: true,
                        fechainicio: Util.calcularFecha(startDate),
                        fechafin: Util.calcularFecha(endDate),
                        meses: Meses,
                        check: checkmarcado
                    }
                }} className="btn-recargar-pagar">
                    <p className="link-pagar">
                        <FontAwesomeIcon icon="shopping-cart" className='icon' />
                        {t('RecargaList.boton',)}</p>
                </Link>
            </div>

        </div>
    );
};
const DatePickerRecargas = withTranslation('common')(DatePickerRecargasTranslated)
export default DatePickerRecargas;