import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import PageLoading from "../PantallaCarga/PageLoading";
import Moment from 'react-moment';
import { useTranslation, withTranslation } from "react-i18next";
import PageError from "../PantallaCarga/PageError";
import { wifi } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';


let value = '';
class ConsumoListItemMegasTranslated extends React.Component {

    state = {
        loading: false,
        error: null,
        color: true
    };
    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        if (this.state.error === true) {
            return <PageError />;
        }

        if (this.state.loading === true) {
            return <PageLoading />;
        } else {
            return (
                <div className="llamadas">
                    <div className="fecha-llamada">
                        <span><b>{t('DetalleConsumo.fecha',)}</b> </span>
                        <span><Moment format="DD/MM/YY " className="fecha">{this.props.consumo.fecha}</Moment></span >
                    </div>
                    <div className="fecha-llamada">
                        <span><b>{t('DetalleConsumo.hora',)}</b> </span>
                        <span>{this.props.consumo.hora}</span >
                    </div>
                    <div className="destino">
                        <span>Megabytes empleados : </span>
                        <span > {this.props.consumo.duracion}</span>
                        <IonIcon className="icon-blue" icon={wifi} />
                    </div>
                </div>
            );
        }
    }
}
const ConsumoListItemMegas = withTranslation('common')(ConsumoListItemMegasTranslated)

function useSearchConsumos(consumos) {
    const [list, setList] = React.useState(consumos)

    useEffect(() => {
        const sortedList = [...list].sort(function (a, b) {
            var dateA = new Date(a.fecha).getTime();
            var dateB = new Date(b.fecha).getTime();
            return dateB > dateA ? 1 : -1;
        });
        setList(sortedList)
    }, [])

    const [query, setQuery] = React.useState("");
    const [fileteredConsumos, setfileteredConsumos] = React.useState(list);


    React.useMemo(() => {
        const result = list.filter((consumo) => {
            return `${consumo.fecha} ${consumo.destino}`
                .toLowerCase()
                .includes(query.toLowerCase());
        });

        setfileteredConsumos(result);
    }, [list, query]);

    return { query, setQuery, fileteredConsumos };
}




function ConsumoListMegasTranslated(props) {
    const [t, i18n] = useTranslation('common');
    const consumos = props.consumos;
    const { query, setQuery, fileteredConsumos } = useSearchConsumos(consumos);

    if (fileteredConsumos.length === 0) {

        return (
            <div>
                <div className="div-buscar">
                    <FontAwesomeIcon icon="search" className="icon" />
                    <input
                        type="text"
                        className="form-control"
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <h5>{t('DetalleConsumo.nohay',)} </h5>
            </div>
        );

    } else {
        return (
            <div className="facturasList">
                <div className="div-buscar">
                    <FontAwesomeIcon icon="search" className="icon" />
                    <input
                        type="text"
                        className="input-buscar"
                        placeholder={t('Contratos.buscar',)}
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <ul className="list-unstyled">

                    {fileteredConsumos.map(consumo => {
                        return (
                            <li key={consumo.id}>
                                <ConsumoListItemMegas consumo={consumo} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );

    }



}
const ConsumoListMegas = withTranslation('common')(ConsumoListMegasTranslated)
export default ConsumoListMegas;
