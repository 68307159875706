import React from "react";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import TicketList from "../../components/Ticket/TicketList";
import api from "../../components/servicios/api";
import "../../styles/GlobalPages.css";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PiePagina from '../../components/Navbar/PiePagina';
import { isPlatform } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';

let userTicket;
const cliente = JSON.parse(localStorage.getItem('user'));
class TicketsTranslated extends React.Component {
  state = {
    loading: true,
    error: false,
    datos: '',
    sinDatos: false,
    mostrar: '2'
  };
  componentDidMount() {
    userTicket = '';
    if (isPlatform('ios') && !isPlatform('mobileweb')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
    this.fetchData();
  }

  handleChange = async (e) => {

    this.setState({ loading: true, mostrar: e.target.value });
    if (e.target.value == '2') {
      this.fetchData();
    } else {
      this.cambiar_datos(e.target.value);
    }

  };

  cambiar_datos = async (valor) => {

    try {
      await api.Clientes.tickets(cliente.usuario, valor).then(
        ({ data }) => (userTicket = data)

      );
      if (Array.isArray(userTicket)) {
        this.setState({ loading: false, datos: userTicket });

      } else if (userTicket == "sin datos" || userTicket == 'No se han encontrado datos para el modelo <b>tickets_app</b>') {
        this.setState({ loading: false, sinDatos: true, datos: '' });
      } else {
        this.setState({ loading: false, error: true });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }

  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      await api.Clientes.ticketsTodos(cliente.usuario).then(
        ({ data }) => (userTicket = data)
      );
      if (Array.isArray(userTicket)) {
        this.setState({ loading: false, datos: userTicket });

      } else if (userTicket == "sin datos" || userTicket == 'No se han encontrado datos para el modelo <b>tickets_app</b>') {
        this.setState({ loading: false, sinDatos: true, datos: '' });
      } else {
        this.setState({ loading: false, error: true });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  render() {
    const { t } = this.props;
    if (this.state.loading === true && !this.state.datos) {
      return <PageLoading />;
    }
    if (this.state.loading === true) {
      return <PageLoading />;
    }
    if (this.state.error) {
      return <PageError />;
    }
    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Home'} />
        </div>
        <div className="titulo-pagina">
          <span className="">{t('Tickets.titulo',)}</span>
        </div>
        <div className="listas">
          <div className="fab-buton">
            <div className="ticket-nuevo">
              <Link to="/TicketNew" className="btn-aceptar">
                <FontAwesomeIcon icon="plus-circle" className="icon-btn" />
                {t('Ticket.nuevo',)}
              </Link>
            </div>
          </div>
          <div className="div-ticket-select">
            <select value={this.state.mostrar} onChange={this.handleChange} className={this.state.datos != '' ? "select-ticket" : 'select-ticket-vacio'}>
              <option value='2'>{t('Tickets.ver',)}{t('Tickets.todos',)}</option>
              <option value='1'>{t('Tickets.ver',)}{t('Tickets.sol',)}</option>
              <option value='0'>{t('Tickets.ver',)}{t('Tickets.pend',)}</option>
            </select>
          </div>
          {this.state.datos != '' ? <TicketList tickets={this.state.datos} /> : <p className="sin-ticket">{t('Tickets.info',)}</p>}
          {this.state.loading && <MiniLoader />}
        </div>
        <PiePagina />
      </div>
    )
  }
}
const Tickets = withTranslation('common')(TicketsTranslated)
export default Tickets;
