import React from "react";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import api from "../../components/servicios/api";
import Moment from 'react-moment';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Keyboard } from '@capacitor/keyboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalPages.css";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import imagenTicket from '../../Imagenes/imagenTicket.png';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import Alertas from "../../components/servicios/Alertas";
import { isPlatform } from '@ionic/react';


let departamento = '';
let asuntos = [];
let respuesta;
let asuntoTicket;
let now = new Date();
let blob11 = '';


class TicketNewTranslated extends React.Component {

  constructor(props) {
    super(props);
    this.buttonAnadir = React.createRef();

    this.state = {
      loading: true,
      error: false,
      datos: undefined,
      form: {
        descripcion: "",
        asunto: "0",
      },
      photo: '',
      alertOk: false,
      alertKo: false,
      alertImagen: false,
      instrucciones: "",
      mostrar: false,
      info: false
    };
  }
  componentDidMount() {
    this.fetchData();
    if (isPlatform('ios') && !isPlatform('mobileweb')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }

  abrirAlertOk = () => {
    this.setState({ alertOk: !this.state.alertOk });
  }
  abrirAlertKo = () => {
    this.setState({ alertKo: !this.state.alertKo });
  }

  abrirAlertImagen = () => {
    this.setState({ alertImagen: !this.state.alertImagen });
  }



  async takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    let imageUrl = image.webPath;
    blob11 = await fetch(image.webPath).then(r => r.blob()).catch(function (err) {
      //console.log(err);
    });
    this.setState({
      photo: imageUrl
    })

    if (blob11.size > 4194304) {
      Alertas.demasiadoGrande();
      this.setState({
        photo: ''
      })
    }

  }

  adjunto = async (idComentario, archivo) => {

    await api.Clientes.nuevaImagen(idComentario, archivo).then((result) => {
      if (result.data == true) {
        this.setState({ loading: false, datos: asuntos });
        this.abrirAlertOk();
      } else {
        this.setState({ loading: false, datos: asuntos });
        this.abrirAlertImagen();
      }
    }).catch(function (err) {
      // console.log(err);
    });

  }

  insertarTicket = async () => {

    if (this.state.form.asunto != 0) {
      this.setState({ loading: true, datos: null });

      const cliente = JSON.parse(localStorage.getItem('user'));

      for (let i = 0; i < asuntos.length; i++) {
        if (asuntos[i].id == this.state.form.asunto)
          departamento = asuntos[i].departamento;

      }

      try {
        await api.Clientes.nuevoTicket(cliente.usuario, departamento, this.state.form.asunto, this.state.form.descripcion, 1).then(
          ({ data }) => (respuesta = data)
        );
        //console.log("id del ticket" + respuesta);
        if (this.state.photo !== '') {

          await api.Clientes.nuevoComentario(respuesta, 'Imagen%20adjunta').then((result) => {
            if (isNaN(parseInt(result.data))) {
              this.setState({ loading: false, datos: asuntos });
              this.abrirAlertImagen();
            } else {
              this.adjunto(result.data, blob11);
            }
          }).catch(function (err) {
            // console.log(err);
          });
        } else {
          if (isNaN(parseInt(respuesta))) {
            this.setState({ loading: false, datos: asuntos });
            this.abrirAlertKo();
          } else {
            this.setState({ loading: false, datos: asuntos });
            this.abrirAlertOk();
          }
        }

      } catch (error) {
        this.setState({ loading: false, error: error });
      }

    } else {
      this.setState({ info: 'true' })
    }


  };

  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      await api.Clientes.asuntoTicket().then(
        ({ data }) => (asuntoTicket = data)
      );
      asuntos = [];
      if (Array.isArray(asuntoTicket)) {
        for (let i = 0; i < asuntoTicket.length; i++) {
          asuntos.push(asuntoTicket[i]);
        }

        this.setState({ loading: false, datos: asuntoTicket });
      } else {
        this.setState({ loading: false, error: true });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  handleChange = async (e) => {


    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,

      },
    });

    if (this.state.form.asunto != 0) {
      this.setState({ info: false })
    } else {
      this.setState({ info: true })
    }

    for (let i = 0; i < asuntos.length; i++) {
      if (asuntos[i].id == this.state.form.asunto) {
        this.setState({ instrucciones: asuntos[i].instrucciones_app });
        if (asuntos[i].instrucciones_app == "" || asuntos[i].instrucciones_app == null) {
          this.setState({ mostrar: false });
        } else {
          this.setState({ mostrar: true });
        }
      }
    }
  };


  render() {
    const { t } = this.props;
    if (this.state.loading === true && !this.state.datos) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError />;
    }

    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Tickets'} />
        </div>
        <div className="titulo-pagina">
          <span className="">{t('TicketNuevo.titulo',)}</span>
        </div>
        <div className="ticket-nuevo">
          <span className="ticket-nuevo-fecha">{t('TicketNuevo.fecha',)}</span>
          <span><Moment format="DD/MM/YYYY    HH:MM:SS  " className="">{now}</Moment></span>
        </div>
        <br></br>
        <div className="ticket-asuntos">
          <span className="">{t('TicketNuevo.asunto',)}  </span>
          <select className="asuntoTicket" name="asunto" onChange={this.handleChange}>
            <option value='0'>{t('TicketNuevo.asuntoSelect',)}</option>
            {this.state.datos.map((dato) =>
              <option key={dato.id} value={dato.id}>{dato.asunto}</option>
            )}
          </select>
        </div>
        <div className={this.state.info ? 'obligarAsunto' : 'hidden'}>{t('TicketNuevo.obligarasunto',)}</div>
        <div className={this.state.mostrar ? "instrucciones-ticket-asuntos" : "hidden"}>
          <span className="">{t('TicketNuevo.instrucciones',)}  </span>
          <span dangerouslySetInnerHTML={{ __html: this.state.instrucciones }}></span>

        </div>
        <div className="descripcion-ticket-label">
          <br></br>
          <span className="descripcion-label">{t('TicketNuevo.descripcion',)}</span>
        </div>
        <div className="descripcion-ticket">
          <br></br>
          <div className="comment">
            <textarea className="textinput" name="descripcion" value={this.state.value1}
              onChange={this.handleChange} id="descripcion" cols="40" rows="6" ></textarea>
          </div>
        </div>
        <div className="imagen">
          <br></br>
          {this.state.photo.length > 0 ?
            <img className="sinImagen"
              src={this.state.photo}
              alt="sinImagen"
              onClick={() => this.takePicture()}
            /> : <img className="imagenTicket"
              src={imagenTicket}
              alt="sinImagen"
              onClick={() => this.takePicture()}
            />}
        </div>
        <div className="pieTicketNuevo">
          {this.state.loading && <MiniLoader />}
          <button className="btn-verde-xl" onClick={() => this.insertarTicket()}>
            <FontAwesomeIcon icon="save" className="icon-btn" />
            <span>    {t('Button.ticket',)}</span>
          </button>
        </div>
        <PiePagina />
        <IonAlert
          isOpen={this.state.alertOk}
          cssClass='correcto'
          header={t('TicketNuevo.ticketOk',)}
          message={`<img src="${Ok}" alt="error" className="errorimg">`}
          buttons={[{
            text: [`${t('Aceptar.acep',)}`],
            handler: () => {
              window.location = '/Tickets';
            }
          }]}
        />
        <IonAlert
          isOpen={this.state.alertKo}
          cssClass='error'
          header={t('TicketNuevo.ticketKo',)}
          message={`<img src="${Error}" alt="error" className="errorimg">`}
          buttons={[{
            text: [`${t('Aceptar.acep',)}`],
            handler: () => {
              window.location = '/Tickets';
            }
          }]}
        />
        <IonAlert
          isOpen={this.state.alertImagen}
          cssClass='error'
          header={t('TicketNuevo.ticketImagen',)}
          message={`<img src="${Error}" alt="error" className="errorimg">`}
          buttons={[{
            text: [`${t('Aceptar.acep',)}`],
            handler: () => {
              window.location = '/Tickets';
            }
          }]}
        />
      </div>
    );
  }
}
const TicketNew = withTranslation('common')(TicketNewTranslated)
export default TicketNew;
