import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import { withTranslation } from "react-i18next";

class DocumentosfirmaTranslated extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="list-component" >
                <div className="num-documento">
                    <span className="documento"> {t('Documentos.numero',)}</span>
                    {this.props.id}
                </div>
                <div className="num-documento">
                    <span className="documento" > {t('Documentos.descrip',)} </span>
                    {this.props.descripcion}
                </div>
                <div className="importe-factura">
                    <a href={this.props.url}> {t('Documentos.firmar',)}</a>
                    <FontAwesomeIcon icon="pencil-alt" className="icon" />
                </div>
            </div >
        )
    }
}
const Documentosfirma = withTranslation('common')(DocumentosfirmaTranslated)
export default Documentosfirma;