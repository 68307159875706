import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import ContratoRecargaList from '../../components/Contratos/ContratoRecargaList';
let userData;
let contratos = [];
let dispositivo;

class RecargasTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        datos: undefined,
        sinDatos: false

    };

    componentDidMount() {
        contratos = [];
        this.fetchData();

    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });
        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.contratosRecarga(cliente.usuario).then(
                ({ data }) => (userData = data)

            );
            if (Array.isArray(userData)) {
                for (let i = 0; i < userData.length; i++) {
                    if (userData[i].id) {
                        await api.Clientes.dispositivoAsociado(userData[i].id).then(
                            ({ data }) => (dispositivo = data)
                        );
                        if (dispositivo === 'NO') {
                            contratos.push(userData[i]);
                        }
                    }
                }
                if (contratos.length == 0) {
                    this.setState({ loading: false, sinDatos: true });
                } else {
                    this.setState({ loading: false, datos: userData, error: false });
                }

            } else {
                this.setState({ loading: false, sinDatos: true });
            }
            this.setState({ loading: false, error: null });
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };




    render() {
        const { t } = this.props;
        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'Recargas'} />
        }
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{t('Recargas.titulo',)}</span>
                </div>
                <div>
                    <ContratoRecargaList contrato={contratos} />
                </div>
                <PiePagina />
            </div>
        )
    }
}
const Recargas = withTranslation('common')(RecargasTranslated)
export default Recargas;