import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from "../../components/servicios/api";
import MiniLoader from '../PantallaCarga/MiniLoader';
let respuesta;
const TarjetaStripe = (props) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [errorIsp, setErrorIsp] = useState(false);
    const stripe = useStripe();
    const elements = useElements();


    useEffect(() => {
        respuesta = '';
        const importe = props.datos.cantidad;
        const factura = props.datos.numfactura;
        const idCobro = props.datos.idCobro;
        console.log(props.datos);

        // al servidor para obtener el secret del cliente
        api.Clientes.stripeCliente(importe, factura, {
        })
            .then(respuesta => {
                //console.log(respuesta.data.clientSecret);
                setClientSecret(respuesta.data.clientSecret);
            });
    }, []);

    const handleChange = async (event) => {
        // avisar de errores en la tarjeta 
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async (ev) => {
        const idCobro = props.datos.idCobro;
        const contrato = props.datos.contrato;
        const fechainicio = props.datos.fechainicio;
        const fechafin = props.datos.fechafin;
        const meses = props.datos.meses;
        const check = props.datos.check;

        console.log('procesando');
        ev.preventDefault();
        setProcessing(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });
        if (payload.error) {
            setError(`Error en el pago ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);

            if (contrato == true) {
                await api.Clientes.capturaPagoRecargas(idCobro, payload.paymentIntent.id, "stripe", fechainicio, fechafin, meses, check).then(
                    ({ data }) => (respuesta = data)
                );
            } else {
                await api.Clientes.capturaPago(idCobro, payload.paymentIntent.id, "stripe").then(
                    ({ data }) => (respuesta = data)
                );
            }
            if (respuesta != "Pago registrado correctamente = 1") {
                setErrorIsp(true);
            } else {
                setSucceeded(true);
            }
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                color: "#666",
                fontSize: "15px",
            },
            invalid: {
                color: "#fa755a",
                fontSize: "fa755a",
            }
        }
    }



    return (
        <form onSubmit={handleSubmit}>
            <CardElement id="card-element" options={cardElementOptions} onChange={handleChange} />
            <button button
                disabled={processing || disabled || succeeded}
                id="submit" className="btn-pagar"
            >
                <span id="button-text">
                    {processing ? (
                        <div className="spinner" id="spinner">
                            <MiniLoader />
                        </div>
                    ) : (
                        "Pagar"
                    )}
                </span>
            </button>
            {/* Muestra los errores durante el proceso de pago */}
            {error && (
                <div className="error-tarjeta" role="alert">
                    {error}
                </div>
            )}
            {/* Pago correcto */}
            <p className="hidden">
                {succeeded ? window.location = "/PagoCorrecto" : ""}
                {errorIsp ? window.location = "/PagoProblemas" : ""}
            </p>
        </form>
    );
};

export default TarjetaStripe;