import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from "react-i18next";
import PageError from "../PantallaCarga/PageError";
import PageLoading from "../PantallaCarga/PageLoading";
import AceptarContacto from '../../components/Alertas/AceptarContacto';
import estrellas from '../../Imagenes/estrellas2.png';

class TarifaBloqueTranslated extends React.Component {
    state = {
        modal: false,
        mostrar: '1',
        error: false,
        alert: false,
        color: ''
    }


    componentDidMount() {
        console.log(this.props)
        if (this.props.destacado == 1) {
            this.setState({ color: this.props.colordestacado });
        } else {
            this.setState({ color: this.props.color });
        }
    }

    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
    }
    abrirAlert = () => {
        this.setState({ alert: true });
    }

    contacto() {
        this.setState({ modal: !this.state.modal, alert: true });
    }


    render() {
        const { t } = this.props;
        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.loading) {
            return <PageLoading />;
        }
        return (
            <div className="container-tarifas">
                <div className="div-container"
                    style={{
                        borderColor: this.state.color
                    }}>
                    <div className="nombre-tarifa"
                        style={{
                            background: this.state.color,
                            borderBottomColor: this.state.color
                        }}>
                        <span className="nombre-tarifa-nom">{this.props.nombre}</span>
                    </div>
                    <div className="bloque">
                        <div className="descripcion">
                            <span className="descripciontarifas">{this.props.descripcion}</span>
                        </div>

                        <div className="precio">
                            <span>{this.props.precio}</span>
                            <span className="precio-mes">€{t('TarifaBloque.mes',)}</span>
                        </div>
                        <div className="detalles">
                            <button className="detalles-btn" onClick={this.abrirModal}
                                style={{
                                    background: this.state.color,

                                }}>
                                <span>{t('TarifaBloque.detalles',)}  </span>
                                <FontAwesomeIcon icon="search-plus" className="icon-white" />
                            </button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} >
                    <ModalHeader className="modal-header-tarifas"
                        style={{
                            background: this.state.color,
                        }}>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModal}>
                                <FontAwesomeIcon icon="times" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="tarifas-descripcion-ampliada">
                        <span dangerouslySetInnerHTML={{ __html: this.props.ampliacion }} />
                    </ModalBody>
                    <ModalFooter>
                        <span>{t('TarifaBloque.interes',)}</span>
                        <button className="btn-rojo-xl-tarifas" onClick={() => this.contacto()}
                            style={{
                                background: this.state.color,
                                borderColor: this.state.color,
                                color: "black"
                            }}>
                            <FontAwesomeIcon icon="paper-plane" className="icon-black" />
                            <span>{t('TarifaBloque.meInteresa',)}</span>
                        </button>
                    </ModalFooter>
                </Modal>
                {this.state.alert && <AceptarContacto dato={this.props} />}
            </div>
        )

    }
};

const TarifaBloque = withTranslation('common')(TarifaBloqueTranslated)
export default TarifaBloque;