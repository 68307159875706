
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IonAlert } from '@ionic/react';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Ok from '../../Imagenes/ok.png';
import Error from '../../Imagenes/error.png';

let userConsumo = '';
let estRo = '';

class RoamingTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: [],
        sinDatos: false,
        estadoRoaming: false,
        mostrarRoaming: false,
        roamingAire: false,
        roamingDatos: 0,
        roamingLlama: 0,
        estadoRoamingData: false,
        estadoRoamingLLama: false,
        ddi: '',
        alertOk: false,
        alertKo: false,
        modal: false,
        modal1: false,
        modalXtra: false

    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userConsumo)) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    abrirModal1 = () => {
        this.setState({ modal1: !this.state.modal1 });
    }

    abrirModalXtra = () => {
        this.setState({ modalXtra: !this.state.modalXtra });
    }

    cerrarModal = () => {
        window.location.reload();
    }

    consultaRoaming = async (ddi) => {
        this.setState({ loading: true, error: null });
        estRo = '';
        if (ddi != '') {
            try {
                await api.Clientes.consultarRoaming(ddi).then(
                    ({ data }) => (estRo = data),
                );
                if (estRo == '1' || estRo == '2') {
                    if (estRo == 1) {
                        this.setState({ estadoRoaming: true, roamingAire: false });
                    } else {
                        this.setState({ estadoRoaming: false, roamingAire: false });
                    }
                    this.setState({ mostrarRoaming: true });
                } else if (typeof (estRo['datos']) && typeof (estRo['llama'])) {
                    if (estRo['datos'] == 1) {
                        this.setState({ estadoRoamingData: true });
                    } else {
                        this.setState({ estadoRoamingData: false });
                    }
                    if (estRo['llama'] == 1) {
                        this.setState({ estadoRoamingLLama: true });
                    } else {
                        this.setState({ estadoRoamingLLama: false });
                    }
                    if (estRo['llama'] == 1 || estRo['datos'] == 1) {
                        this.setState({ estadoRoaming: true });
                    }
                    this.setState({ roamingAire: true });
                } else {
                    this.setState({ mostrarRoaming: false, roamingAire: false });
                }
                this.setState({ loading: false });
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }

    activarRoaming = async (opcion, accion) => {
        console.log('click activa')
        let activa = '';
        if (this.state.ddi != '') {
            if (opcion == 'datos') {
                var datosR = {
                    "datos": accion
                }
            } else if (opcion == 'llama') {
                var datosR = {
                    "llama": accion
                }
            } else {
                var datosR = accion
            }


            this.setState({ loading: true, error: null });
            try {
                await api.Clientes.activarRoaming(this.state.ddi, JSON.stringify(datosR)).then(
                    ({ data }) => (activa = data),
                );
                this.setState({ loading: false, error: null });
                if (activa == 'OK') {
                    this.abrirAlertOk();
                } else {
                    this.abrirAlertKo();
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    handleChange = async (e) => {
        this.setState({ ddi: e.target.value });
        this.consultaRoaming(e.target.value);
    };


    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }

        return <div className='roaming-herramientas'>
            <div>
                <div className="titulo-pagina-pequeno">
                    <span className="Roaming">Roaming</span>
                </div>
            </div>
            <select name="telefono" onChange={this.handleChange} className="select-tlf" >
                <option key="0" value="" disabled selected>{t('Consumo.numero',)}</option>
                {this.state.datos.map((dato) =>
                    <option key={dato.id} value={dato}>{dato}</option>
                )}
            </select>
            {this.state.mostrarRoaming ?
                <div className='activar-roaming'>
                    <span className='rayita-min'>{t('ConsumoReal.estado',)}</span>
                    {this.state.estadoRoaming ? <div className='float-roaming'>
                        <div className="switch-button-roaming">
                            <input
                                type="checkbox"
                                name="switchLabel"
                                id="switchLabel"
                                onClick={() => this.activarRoaming('xtra', 2)}
                                className="switch-button__checkbox-roaming" />
                            <label htmlFor="switchLabel" className="switch-button__label-roaming" />
                        </div>
                        <div> Puedes desactivar el roaming pulsando en el botón</div>
                    </div>
                        : <div className='float-roaming'>
                            <div className="switch-button-roaming-apa">
                                <input
                                    type="checkbox"
                                    name="switchLabel"
                                    id="switchLabel"
                                    onClick={() => this.abrirModalXtra()}
                                    className="switch-button__checkbox-roaming-apa" />
                                <label htmlFor="switchLabel" className="switch-button__label-roaming-apa" />
                            </div>
                        </div>
                    }

                </div>
                : ''}
            {this.state.roamingAire && <div><div className='activar-roaming'>
                <span className='rayita-min'>Datos</span>
                {this.state.estadoRoamingData ? <div className='float-roaming'>
                    <div className="switch-button-roaming1">
                        <input
                            type="checkbox"
                            name="switchLabelLL"
                            id="switchLabelLL"
                            onClick={() => this.activarRoaming('datos', 2)}
                            className="switch-button__checkbox-roaming1" />
                        <label htmlFor="switchLabelLL" className="switch-button__label-roaming1" />
                    </div>
                </div>
                    : <div className='float-roaming'>
                        <div className="switch-button-roaming-apa1">
                            <input
                                type="checkbox"
                                name="switchLabelLL"
                                id="switchLabelLL"
                                onClick={() => this.abrirModal1()}
                                className="switch-button__checkbox-roaming-apa1" />
                            <label htmlFor="switchLabelLL" className="switch-button__label-roaming-apa1" />
                        </div>
                    </div>
                }

            </div>
                <div className='activar-roaming'>
                    <span className='rayita-min'>llamadas</span>
                    {this.state.estadoRoamingLLama ? <div className='float-roaming'>
                        <div className="switch-button-roaming">
                            <input
                                type="checkbox"
                                name="switchLabelDatos"
                                id="switchLabelDatos"
                                onClick={() => this.activarRoaming('llama', 2)}
                                className="switch-button__checkbox-roaming" />
                            <label htmlFor="switchLabelDatos" className="switch-button__label-roaming" />
                        </div>
                    </div>
                        : <div className='float-roaming'>
                            <div className="switch-button-roaming-apa">
                                <input
                                    type="checkbox"
                                    name="switchLabelDatos"
                                    id="switchLabelDatos"
                                    onClick={() => this.abrirModal()}
                                    className="switch-button__checkbox-roaming-apa" />
                                <label htmlFor="switchLabelDatos" className="switch-button__label-roaming-apa" />
                            </div>
                        </div>
                    }

                </div></div>}
            <Modal isOpen={this.state.modalXtra} className="roaming-modal">
                <ModalHeader className="modal-header-roaming">
                    <span style={{
                        fontWeight: 800,
                    }}>{t('ConsumoReal.info5',)}</span>
                    <div className="modal-cerrar-tarifa">
                        <button className="modal-cerrar-tarifa" onClick={this.abrirModalXtra}>
                            <FontAwesomeIcon icon="times" className="icon-black-tarifas" />
                        </button>
                    </div>
                </ModalHeader>
                <ModalBody className="modal-body-roaming">
                    <p>{t('ConsumoReal.info1',)}</p>
                    <p>{t('ConsumoReal.info2',)}</p>
                    <p>{t('ConsumoReal.info3',)}</p>
                    <p>{t('ConsumoReal.info4',)}</p>

                </ModalBody>
                <ModalFooter className='modal-footer-roaming'>
                    <button className="btn-rojo-xl-tarifas" onClick={() => this.activarRoaming('xtra', 1)}>
                        <span>{t('ConsumoReal.activar',)}</span>
                    </button>
                </ModalFooter>
            </Modal>
            {<Modal isOpen={this.state.modal} className="roaming-modal">
                <ModalHeader className="modal-header-roaming">
                    <span style={{
                        fontWeight: 800,
                    }}>{t('ConsumoReal.info5',)}</span>
                    <div className="modal-cerrar-tarifa">
                        <button className="modal-cerrar-tarifa" onClick={this.cerrarModal}>
                            <FontAwesomeIcon icon="times" className="icon-black-tarifas" />
                        </button>
                    </div>
                    {this.props.nombre}
                </ModalHeader>
                <ModalBody className="modal-body-roaming">
                    <p>{t('ConsumoReal.info1',)}</p>
                    <p>{t('ConsumoReal.info2',)}</p>
                    <p>{t('ConsumoReal.info3',)}</p>
                    <p>{t('ConsumoReal.info4',)}</p>

                </ModalBody>
                <ModalFooter className='modal-footer-roaming'>
                    <button className="btn-rojo-xl-tarifas" onClick={() => this.activarRoaming('llama', 1)}>
                        <span>{t('ConsumoReal.activar',)}</span>
                    </button>
                </ModalFooter>
            </Modal>}
            <Modal isOpen={this.state.modal1} className="roaming-modal">
                <ModalHeader className="modal-header-roaming">
                    <span style={{
                        fontWeight: 800,
                    }}>{t('ConsumoReal.info5',)}</span>
                    <div className="modal-cerrar-tarifa">
                        <button className="modal-cerrar-tarifa" onClick={this.cerrarModal}>
                            <FontAwesomeIcon icon="times" className="icon-black-tarifas" />
                        </button>
                    </div>
                    {this.props.nombre}
                </ModalHeader>
                <ModalBody className="modal-body-roaming">
                    <p>{t('ConsumoReal.info1',)}</p>
                    <p>{t('ConsumoReal.info2',)}</p>
                    <p>{t('ConsumoReal.info3',)}</p>
                    <p>{t('ConsumoReal.info4',)}</p>

                </ModalBody>
                <ModalFooter className='modal-footer-roaming'>
                    <button className="btn-rojo-xl-tarifas" onClick={() => this.activarRoaming('datos', 1)}>
                        <span>{t('ConsumoReal.activar',)}</span>
                    </button>
                </ModalFooter>
            </Modal>
            <IonAlert
                isOpen={this.state.alertOk}
                cssClass='correcto'
                header={t('ConsumoReal.roamingOk',)}
                message={`<img src="${Ok}" alt="error" className="errorimg">`}
                buttons={[{
                    text: [`${t('Aceptar.acep',)}`],
                    handler: () => {
                        window.location = '/Herramientas';
                    }
                }]}
            />
            <IonAlert
                isOpen={this.state.alertKo}
                cssClass='error'
                header={t('ConsumoReal.roamingko',)}
                message={`<img src="${Error}" alt="error" className="errorimg">`}
                buttons={[{
                    text: [`${t('Aceptar.acep',)}`],
                    handler: () => {
                        window.location = '/Herramientas';
                    }
                }]}
            />
        </div>
    }

}

const Roaming = withTranslation('common')(RoamingTranslated)
export default Roaming;