import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { Link } from 'react-router-dom';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { IonAlert } from '@ionic/react';
import Pregunta from '../../Imagenes/pregunta.png';
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import Tabs from '../../components/TabsClientes/Tabs';
import Util from '../../components/servicios/Util';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import Moment from 'react-moment';
import Oferta from '../../components/Ofertas/Oferta';

let userData;
let ofertasCli;
let documentos;
let cambiarEstado;
let citas;


class ClienteTranslated extends React.Component {

    state = {
        nombreEmpresa: '',
        nombre: '',
        alert: false,
        loading: true,
        datos: undefined,
        error: null,
        sinDatos: false,
        documentosR: [],
        ofertasR: [],
        citasR: [],
        form: {
            tipocuenta: '6',
            estadoOferta: 'PENDIENTE'
        },
        alertOk: false,
        alertKo: false,
    };

    componentDidMount() {
        this.setState({ ofertasR: [], documentosR: [], datos: [] });
        this.fetchData();
        this.fetchDataOfertas();
        this.fetchDataDocumentos();
        this.fetchDataCitas();
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null, datos: undefined });
        userData = '';
        try {
            await api.Clientes.buscarCliente(this.props.location.state.codigo).then(
                ({ data }) => (userData = data)
            );

            if (Array.isArray(userData)) {
                this.setState({ loading: false, datos: userData, sinDatos: false });

            } else if (userData == "sin datos" || "No se han encontrado datos para el modelo <b>buscarClientes</b>" == userData) {
                this.setState({ loading: false, sinDatos: true, datos: false });
            } else {
                this.setState({ loading: false, error: true });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    };


    fetchDataOfertas = async () => {
        this.setState({ loading: true, error: null });
        ofertasCli = '';
        try {
            await api.Clientes.listarOfertas(this.props.location.state.codigo).then(
                ({ data }) => (ofertasCli = data)
            );
            if (Array.isArray(ofertasCli)) {
                this.setState({ loading: false, ofertasR: ofertasCli });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    };

    fetchDataDocumentos = async () => {
        this.setState({ loading: true, error: null });
        documentos = '';
        try {
            await api.Clientes.getdocumentos(this.props.location.state.codigo).then(
                ({ data }) => (documentos = data)
            );
            if (Array.isArray(documentos)) {
                this.setState({ loading: false, documentosR: documentos });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    };

    fetchDataCitas = async () => {
        this.setState({ loading: true, error: null });
        citas = '';
        try {
            await api.Clientes.listarCitas(this.props.location.state.codigo).then(
                ({ data }) => (citas = data)
            );
            if (Array.isArray(citas)) {
                this.setState({ loading: false, citasR: citas });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }
    };


    guardarEstado = async () => {
        try {
            await api.Clientes.modificarTipocliente(this.props.location.state.codigo, this.state.form.tipocuenta).then(
                ({ data }) => (cambiarEstado = data)
            );

            if (cambiarEstado == 'OK') {
                this.abrirAlertOk();
            } else {
                this.abrirAlertKo();
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, error: true });
        }

    }

    handleChange = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
        console.log(e.target.name + ':' + e.target.value)
    };

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }


    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="tabsclientes">
                    <IonLabel className="titulo-pagina">Cliente</IonLabel>
                </div>
                <Tabs >

                    <div label="Datos">
                        {this.state.datos &&
                            <IonList className="datosCliente" >
                                <IonItem className="datosClienteDescripcion">
                                    <FontAwesomeIcon icon="user" className="icon" />
                                    <IonLabel className="inputs">{userData[0].codigo + ' - ' + userData[0].nombrecompleto}</IonLabel>
                                </IonItem>
                                <IonItem className="datosClienteDescripcion">
                                    <FontAwesomeIcon icon="user-plus" className="icon" />
                                    <IonLabel className="inputs">{userData[0].domicilio + ', ' + userData[0].numero}</IonLabel>
                                </IonItem>
                                <IonItem className="datosClienteDescripcion">
                                    <FontAwesomeIcon icon="id-card" className="icon" />
                                    <IonLabel className="inputs">{userData[0].nif + ' ' + userData[0].cif}</IonLabel>
                                </IonItem>
                                <IonItem className="datosClienteDescripcion">
                                    <FontAwesomeIcon icon="at" className="icon" />
                                    <IonLabel className="inputs">{userData[0].email}</IonLabel>
                                </IonItem>
                                <IonItem className="datosClienteDescripcion">
                                    <FontAwesomeIcon icon="phone-alt" className="icon" />
                                    <IonLabel id="telefono" name="telefono" className="inputs">{userData[0].telefono1}</IonLabel>
                                </IonItem>
                                <IonItem className="datosClienteDescripcion">
                                    <FontAwesomeIcon icon="mobile-alt" className="icon icon-movil" />
                                    <IonLabel className="inputs">{userData[0].movil}</IonLabel>
                                </IonItem>
                                {userData[0].tipocuenta == '6' ?

                                    <div className='div-cliente-potencial'>
                                        <IonLabel className="inputs">Tipo Cliente</IonLabel>
                                        <select className='tipoclienteFicha' onChange={this.handleChange} value={this.state.form.tipocuenta} name='tipocuenta'>
                                            <option value='6'>Potencial</option>
                                            <option value='1'>Normal</option>
                                        </select>
                                        <button className='btn-aceptar tipoclientebtn' onClick={() => this.guardarEstado()} > Guardar tipo</button>

                                    </div> : ''}
                            </IonList>

                        }
                    </div>
                    <div label="Documentación">
                        <div className='docu-cliente'>
                            {this.state.documentosR.length > 0 ?
                                this.state.documentosR.map((documento) =>
                                    <div>
                                        <div>
                                            <FontAwesomeIcon icon="file" className="icon icon-movil" />
                                            <span>Titulo : {documento}</span>
                                        </div>
                                    </div>
                                )
                                : ''}
                            <div>
                                <FontAwesomeIcon icon="file" className="icon icon-movil" />
                                <span>Condiciones Generales.pdf
                                </span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon="folder" className="icon icon-movil" />
                                <span>Dni
                                </span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon="file-excel" className="icon icon-movil" />
                                <span>Condiciones Generales.pdf
                                </span>
                                <FontAwesomeIcon icon="file-download" className="icon icon-movil" />
                            </div>
                        </div>
                    </div>
                    <div label="Acciones comerciales">
                        <div>
                            <span>Ofertas realizadas</span>
                        </div>
                        <hr />
                        <div className='ofertas-cliente'>
                            {this.state.ofertasR.length > 0 ?
                                this.state.ofertasR.map((oferta) =>
                                    <Oferta oferta={oferta} />
                                    /*<div>
                                        <div>
                                            <span>Titulo : {oferta.titulo}</span>
                                        </div>
                                        <div>
                                            <span>Descripción: {oferta.descripcion} </span>
                                        </div>
                                        <div className='ofertas-estado'>
                                            <span>Estado:  </span>
                                            <select className='tipoclienteFicha' onChange={this.handleChange} name='estadoOferta'>
                                                <option value='PENDIENTE'>PENDIENTE</option>
                                                <option value='CANCELADA'>PRESENTADA</option>
                                                <option value='ACEPTADA'>ACEPTADA</option>
                                                <option value='CANCELADA'>CANCELADA</option>
                                            </select>
                                        </div>

                                    </div>*/
                                ) : ''}
                        </div>
                        <hr />
                        <div className='ofertas-estado'>
                            <Link
                                to={{
                                    pathname: `/NuevaOferta/`,
                                    state: {
                                        codigo: `${this.props.location.state.codigo}`,
                                    }
                                }}
                            >
                                <button className='btn-aceptar'>Crear Oferta</button>
                            </Link>

                        </div>
                        <hr />
                        <div>
                            <span>Eventos / Citas</span>
                        </div>
                        <hr />
                        <div className='ofertas-cliente'>
                            {this.state.citasR.length > 0 ?
                                this.state.citasR.map((oferta) =>
                                    <div className='citas-div'>
                                        <div>
                                            <span>Titulo : {oferta.titulo}</span>
                                        </div>
                                        <div>
                                            <span>Descripción: {oferta.descripcion} </span>
                                        </div>
                                        <div className='ofertas-estado'>
                                            <span>Fecha: <Moment format="DD/MM/YYYY ">{oferta.fecha}</Moment> </span>
                                            <span>Hora:  {oferta.hora} </span>
                                        </div>

                                    </div>
                                ) : ''}
                        </div>
                        <hr />
                        <div className='ofertas-estado'>
                            <Link
                                to={{
                                    pathname: `/NuevaCita/`,
                                    state: {
                                        codigo: `${this.props.location.state.codigo}`,
                                    }
                                }}
                            >
                                <button className='btn-aceptar'>Crear Evento</button>
                            </Link>

                        </div>

                    </div>
                </Tabs>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('Clientes.alertaOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Clientes';
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('Clientes.alertako',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />

            </div>
        );

    }
};
const Cliente = withTranslation('common')(ClienteTranslated)
export default Cliente;
