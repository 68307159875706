import React from 'react';
import api from '../../components/servicios/api';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from "react-i18next";
import Logotipo from '../../Imagenes/Logotipo.png';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import Alertas from '../../components/servicios/Alertas';
import i18next from "i18next";
import { IonAlert } from '@ionic/react';
import PiePagina from '../../components/Navbar/PiePagina';
import DatosEmpresa from '../../components/Index/DatosEmpresa';


import "../../styles/GlobalPages.css";

let pasarelas = [];
let respuesta = '';
let numero;
let pasarela = '';

numero = Math.floor(Math.random() * (999999 - 100000)) + 100000;

class RecuperaContraseniaTranslated extends React.Component {

    state = {
        form: {
            codigo: '',
        },
        remitente: '',
        imagen: '',
        texto: `Ha solicitado recuperar su contraseña el código de seguridad es ${numero}`,
        error: false,
        loading: false,
        alert: false,
        telefono: '',
        usuario: ''
    };
    componentDidMount() {
        this.enviarSms();
        //console.log(numero);
    }

    abrirAlert = () => {
        this.setState({ alert: !this.state.alert });
    }


    enviarSms = async () => {
        this.setState({ loading: true, error: null });
        const idioma = JSON.parse(localStorage.getItem("idioma"));
        i18next.changeLanguage(idioma);
        let empresa = await DatosEmpresa.obtener(idioma);

        const empresaConf = JSON.parse(localStorage.getItem("empresa"));
        console.log(empresaConf)
        this.setState({ remitente: empresaConf.nombreComercial })
        console.log(this.state.remitente)

        const cliente = JSON.parse(localStorage.getItem('user'));
        if (cliente == null) {
            this.setState({
                error: true
            });
        } else {
            this.setState({ telefono: cliente.movil, usuario: cliente.usuario });
        }

        this.setState({ telefono: cliente.movil });
        if (cliente.movil == '' || cliente.movil == null || cliente.movil == undefined) {

            this.setState({ telefono: cliente.sms });
        }
        await api.Clientes.pasarelas_sms()
            .then(({ data }) => pasarelas = data);

        pasarela = pasarelas[0].id;
        if (!Array.isArray(pasarelas)) {
            Alertas.volver(idioma);
        } else {

            await this.sms();
        }

    }


    sms = async () => {
        await api.Clientes.envioSMS(this.state.usuario, pasarela, this.state.remitente, this.state.texto, this.state.telefono).then((result) => {
            if (result.data != '') {
                this.setState({ loading: false, error: false });
            } else {
                this.setState({ loading: false, error: true });
            }
            //this.setState({ loading: false, error: false });
        }).catch(function (err) {
            console.log(err);
        });


    }

    handleChange = async e => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }
    recupera = async () => {

        if (numero == this.state.form.codigo) {
            window.location = '/CambiarContraseniaIndex';

        } else {
            this.abrirAlert();
        }
    };



    render() {
        if (this.state.loading) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }
        const { t } = this.props;
        return (
            <div className="container h-100">
                <div className="Home_container">
                    <img className="Logotipo"
                        src={Logotipo}
                        alt="Logotipo" />
                </div>
                <div className="texto-info-recordar">
                    <p> {t('Recupera.info',)}</p>
                    <div className="info-recordar">
                        <span className="info-recordar">{t('Recupera.codigoinfo',)} </span>
                    </div>
                </div>
                <div className="formularioRecuperara">
                    <form onSubmit={this.props.onSubmit}>
                        <div className="form-contraseña">
                            <span className="label"> </span>
                            <input
                                onChange={this.handleChange}
                                className="form-input"
                                type="text"
                                name="codigo"
                                placeholder={t('Recupera.codigo',)}
                            />
                        </div>
                        <button
                            type="button"
                            onClick={() => this.recupera()}
                            className="btn-aceptar btonenviarCont" >
                            <FontAwesomeIcon icon="paper-plane" className="icon-btn" />{t('Recupera.enviar',)}
                        </button>
                        <Link to="/" className="btnrecuperar">
                            <FontAwesomeIcon icon="times-circle" className="icon-red" /><span>{t('Recupera.volver',)}</span>
                        </Link>
                    </form>
                </div>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alert}
                    cssClass='avisos'
                    message={t('Recupera.error',)}
                    buttons={[`${t('Aceptar.acep',)}`]}
                />
            </div>
        )
    }
}
const RecuperaContrasenia = withTranslation('common')(RecuperaContraseniaTranslated)
export default RecuperaContrasenia;