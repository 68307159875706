import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import GraficoDonut from '../Home/GraficoDonut';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


let estRo = '';
let consumo1 = [];
let fecha = new Date();
let anio = fecha.getFullYear();
let mes = fecha.getMonth() + 1;
if (mes < 10) {
    mes = '0' + mes;
}
const fechaApi = anio + '' + mes;
class ConsumoRealXtraComTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        ddi: '',
        loading: false,
        restante: '0000',
        total: '00',
        medida: 'GB',
        idActual: '',
        icono: 'datos',
        tarifa: '',
        form: {
            telefono: ''
        },
        sinDatos: false,
        mostrarTarifa: false,
        tarifas: '',
        agrupar: false,
        restanteDatos: 0,
        restanteLlamadas: 0,
        restanteSms: 0,
        totalDatos: 0,
        totalSms: 0,
        totalLLamadas: 0,
        total2: '00',
        restante2: '0000',
        restantedatosB: 0,
        restanteLlamadasB: 0,
        restanteSmsB: 0,
        totalDatosB: 0,
        totalSmsB: 0,
        totalLLamadasB: 0,
        graficoExtra: false,
        llamadas: 0,
        sms: 0,
        tarifaContratada: '',
        estadoRoaming: false,
        mostrarRoaming: false,
        alertOk: false,
        alertKo: false,
        modal: false

    };

    componentDidMount() {
        this.consultaConsumo();
        this.consultaRoaming();
    }

    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    consultaRoaming = async () => {
        estRo = '';
        if (this.props.ddi != '') {
            try {
                await api.Clientes.consultarRoaming(this.props.ddi).then(
                    ({ data }) => (estRo = data),
                );
                if (estRo == '1' || estRo == '2') {
                    if (estRo == 1) {
                        this.setState({ estadoRoaming: true });
                    } else {
                        this.setState({ estadoRoaming: false });
                    }
                    this.setState({ mostrarRoaming: true });
                } else {
                    this.setState({ mostrarRoaming: false });
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    consultaConsumo = async () => {
        consumo1 = [];
        if (this.props.ddi != '') {
            this.setState({ loading: true, error: null });
            try {
                await api.Clientes.consumoReal(this.props.ddi).then(
                    ({ data }) => (consumo1 = data),
                );
                this.setState({ loading: false, error: null });
                if (consumo1 != '' && consumo1 != 'No existen Consumos para el activo indicado por el Cableoperador' && consumo1 != 'Debe especificar los campos obligatorios ddi') {

                    this.consumoAgrupado(consumo1);
                    this.setState({ loading: false, error: null, mostrarTarifa: false, tarifas: consumo1 });
                } else {

                    this.setState({ loading: false, error: null, sinDatos: true });
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    };

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }

    activarRoaming = async (accion) => {
        let activa = '';
        if (this.props.ddi != '') {
            this.setState({ loading: true, error: null });
            try {
                await api.Clientes.activarRoaming(this.props.ddi, accion).then(
                    ({ data }) => (activa = data),
                );
                this.setState({ loading: false, error: null });
                if (activa == 'OK') {
                    this.abrirAlertOk();
                } else {
                    this.abrirAlertKo();
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }





    consumoAgrupado = (array) => {
        let datos = 0;
        let datosconsu = 0;
        let llama = 0;
        let llamaconsu = 0;
        let sms = 0;
        let smsconsu = 0;
        let restantedatos = 0;
        let restantellama = 0;
        let restantesms = 0;

        let datosB = 0;
        let datosconsuB = 0;
        let llamaB = 0;
        let llamaconsuB = 0;
        let smsB = 0;
        let smsconsuB = 0;
        let restantedatosB = 0;
        let restantellamaB = 0;
        let restantesmsB = 0;
        let tarifas = array[0].listBonos
        let bonoExtra = false;
        let ilimitada = 0;
        let minutosGas = 0;
        for (let i = 0; i < tarifas.length; i++) {

            if (Array.isArray(tarifas[i].listConsumos)) {
                let listaCosum = tarifas[i].listConsumos;
                for (let j = 0; j < listaCosum.length; j++) {

                    if (tarifas[i].nombreProducto != 'PROMOCION 10GB Extra 1 MESES COMMUNITY') {

                        if (listaCosum[j].volumenTotal != 'ILIM') {
                            datos = (Number(datos) + Number(listaCosum[j].volumenTotal));
                            datosconsu = (Number(datosconsu) + Number(listaCosum[j].volumen));
                        }

                        if (listaCosum[j].vozNacionalTotal != 'ILIM' && listaCosum[j].vozMvnoTotal != 'ILIM') {
                            llama = (Number(llama) + Number(listaCosum[j].vozNacionalTotal) + Number(listaCosum[j].vozMvnoTotal));
                            llamaconsu = (Number(llamaconsu) + Number(listaCosum[j].vozNacional) + Number(listaCosum[j].vozMvno));
                        } else {
                            ilimitada++;
                        }

                        minutosGas = (Number(minutosGas) + Number(listaCosum[j].vozNacional) + Number(listaCosum[j].vozMvno));

                        if (listaCosum[j].smsInternacionalTotal != 'ILIM' && listaCosum[j].smsMvnoTotal != 'ILIM' && listaCosum[j].smsNacionalTotal != 'ILIM' && listaCosum[j].smsPremiumTotal != 'ILIM') {
                            sms = (Number(sms) + Number(listaCosum[j].smsInternacionalTotal) + Number(listaCosum[j].smsMvnoTotal) + Number(listaCosum[j].smsNacionalTotal) + Number(listaCosum[j].smsPremiumTotal));
                            smsconsu = (Number(smsconsu) + Number(listaCosum[j].smsInternacional) + Number(listaCosum[j].smsMvno) + Number(listaCosum[j].smsNacional) + Number(listaCosum[j].smsPremium));
                        }

                    } else if (tarifas[i].nombreProducto == 'PROMOCION 10GB Extra 1 MESES COMMUNITY') {
                        if (listaCosum[j].volumenTotal != 'ILIM') {
                            datosB = listaCosum[j].volumenTotal;
                            datosconsuB = listaCosum[j].volumen;
                        }

                        if (listaCosum[j].vozNacionalTotal != 'ILIM' && listaCosum[j].vozMvnoTotal != 'ILIM') {
                            llamaB = (Number(llamaB) + Number(listaCosum[j].vozNacionalTotal) + Number(listaCosum[j].vozMvnoTotal));
                            llamaconsuB = (Number(llamaconsuB) + Number(listaCosum[j].vozNacional) + Number(listaCosum[j].vozMvnoTotal));
                        }

                        if (listaCosum[j].smsInternacionalTotal != 'ILIM' && listaCosum[j].smsMvnoTotal != 'ILIM' && listaCosum[j].smsNacionalTotal != 'ILIM' && listaCosum[j].smsPremiumTotal != 'ILIM') {
                            smsB = (Number(smsB) + Number(listaCosum[j].smsInternacionalTotal) + Number(listaCosum[j].smsMvnoTotal) + Number(listaCosum[j].smsNacionalTotal) + Number(listaCosum[j].smsPremiumTotal));
                            smsconsuB = (Number(smsconsuB) + Number(listaCosum[j].smsInternacional) + Number(listaCosum[j].smsMvno) + Number(listaCosum[j].smsNacional) + Number(listaCosum[j].smsPremium));
                        }

                        bonoExtra = true;
                    }
                }
            }


        }
        if (array['tarifa'] != 0) {
            if (typeof array['tarifa']['datos_app'] && array['tarifa']['datos_app'] != undefined && array['tarifa']['datos_app'] != '' && array['tarifa']['datos_app'] != null) {
                console.log('entro ')
                datos = array['tarifa']['datos_app'] * 1024;
            }
            if (typeof array['tarifa']['sms'] && array['tarifa']['sms'] != undefined && array['tarifa']['sms'] != '' && array['tarifa']['sms'] != null) {

                sms = array['tarifa']['sms'];
            }
            if (typeof array['tarifa']['minutos_app'] && array['tarifa']['minutos_app'] != undefined && array['tarifa']['minutos_app'] && array['tarifa']['minutos_app'] != null) {

                llama = array['tarifa']['minutos_app'];
                if (llama == '-1') {
                    llama = 'Ilim.';
                    ilimitada++;
                }
            }

        }

        if (datos > datosconsu) {
            let datosC = datosconsu;
            restantedatos = datos - datosC;
            restantedatos = Number(restantedatos / 1024).toFixed(2);
        } else {
            restantedatos = 0;
        }

        if (sms > smsconsu) {
            restantesms = sms - smsconsu;
        } else {
            restantesms = 0;
        }

        if (llama > llamaconsu) {
            restantellama = llama - llamaconsu;
        } else {
            if (ilimitada > 0) {
                restantellama = 'Ilim.'
                llama = 'Ilim.'
            } else {
                restantellama = 0;
            }

        }
        datos = Number(datos / 1024);

        if (array['tarifa'] != 0) {
            this.setState({ sms: smsconsu, minutos: minutosGas, tarifaContratada: array['tarifa']['nombre_comercial_app'] });
        } else {
            this.setState({ sms: smsconsu, minutos: minutosGas, tarifaContratada: ' ' });
        }


        if (Number(datosB) > Number(datosconsuB)) {
            restantedatosB = datosB - datosconsuB;
            restantedatosB = Number(restantedatosB / 1024).toFixed(2);
        } else {
            restantedatosB = 0;
        }

        if (smsB > smsconsuB) {
            restantesmsB = smsB - smsconsuB;
        } else {
            restantesmsB = 0;
        }

        if (llamaB > llamaconsuB) {
            restantellamaB = llamaB - llamaconsuB;
        } else {
            restantellamaB = 0;
        }

        datosB = Number(datosB / 1024);

        this.setState({ totalDatos: datos, restanteDatos: restantedatos, totalSms: sms, restanteSms: restantesms, totalLLamadas: llama, restanteLlamadas: restantellama });
        this.setState({ totalDatosB: datosB, restantedatosB: restantedatosB, totalSmsB: smsB, restanteSmsB: restantesmsB, totalLLamadasB: llamaB, restanteLlamadasB: restantellamaB });
        this.setState({ total: datos, restante: restantedatos, medida: 'GB' });
        this.setState({ total2: datosB, restante2: restantedatosB, medida: 'GB', graficoExtra: bonoExtra });
    }

    datos = () => {
        this.setState({ medida: 'GB', restante: this.state.restanteDatos, total: this.state.totalDatos });
        this.setState({ medida: 'GB', restante2: this.state.restantedatosB, total2: this.state.totalDatosB });
    }

    llamadas = () => {
        this.setState({ medida: 'MIN', restante: this.state.restanteLlamadas, total: this.state.totalLLamadas });
        this.setState({ medida: 'MIN', restante2: this.state.restanteLlamadasB, total2: this.state.totalLLamadasB });
    }

    sms = () => {
        this.setState({ medida: 'SMS', restante: this.state.restanteSms, total: this.state.totalSms, medida: 'SMS' });
        this.setState({ medida: 'SMS', restante2: this.state.restanteSmsB, total2: this.state.totalSmsB, medida: 'SMS' });
    }

    render() {
        const { t } = this.props;

        if (this.state.loading === true && !this.state.array) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }

        if (this.state.graficoExtra) {
            return (
                <div className="consumo-home-global">
                    {this.state.tarifaContratada != '' ? <div className='nombretarifa'><span><b>{t('Consumo.contratada',)}</b></span><p>{this.state.tarifaContratada}</p></div> : ''}
                    {this.state.sinDatos && <span>Sin datos para esta línea</span>}
                    <div className='graficos-xtra'>
                        <span className='nombre-tarifa-xtra'><b>{t('Consumo.tarifa',)}</b></span>
                        <GraficoDonut
                            restante={this.state.restante}
                            total={this.state.total}
                            medida={this.state.medida}
                            color={'#fcba0c'}
                            iconos={'grey'}
                            css={'grafico-gb'}
                            responsive={false} />
                    </div>
                    <div className='graficos-xtra'>
                        <span className='nombre-tarifa-xtra'><b>{t('Consumo.bono',)}</b></span>
                        <GraficoDonut
                            restante={this.state.restante2}
                            total={this.state.total2}
                            medida={this.state.medida}
                            color={'#000000'}
                            iconos={'grey'}
                            css={'grafico-gb'}
                            responsive={false} />

                    </div>

                    <div className="contenedor-iconos" id="sms">
                        <button className="icono-linea-sms" onClick={() => this.sms()} >
                            <FontAwesomeIcon icon="sms" className={"iconos-consumo-home"} />
                        </button>
                        <button className="icono-linea-llama" onClick={() => this.llamadas()} id="llamadas">
                            <FontAwesomeIcon icon="phone-alt" className={"iconos-consumo-home"} />
                        </button>
                        <button className="icono-linea-gb" onClick={() => this.datos()} id="datos">
                            <div className={"iconos-consumo-home-dobles"} id="datos">
                                <FontAwesomeIcon icon="arrow-up" className={"iconos-gris"} id='datos' />
                                <FontAwesomeIcon icon="arrow-down" className={"iconos-gris"} id="datos" />
                            </div>

                        </button>

                    </div>
                    {process.env.REACT_APP_APP_NAME == 'TMOVIL' ? <div className='resumen-consumo-rayas'>
                        <div className='resumen-consumo-rayas-min'>
                            <span className='rayita-min'>{t('Consumo.llama',)}</span>
                            <span className='rayita-min-cantidad'>{this.state.minutos} {t('Consumo.minutos',)}</span>
                        </div>
                        <div className='raya-abajo'>

                        </div>
                        <div className='resumen-consumo-rayas-sms'>
                            <span className='rayita-min'>SMS</span>
                            <span className='rayita-min-cantidad'>{this.state.sms} </span>
                        </div>
                        {this.state.mostrarRoaming ?
                            <div className='activar-roaming'>
                                <span className='rayita-min'>{t('ConsumoReal.roaming',)}</span>
                                {this.state.estadoRoaming ?
                                    <span className='boton-desactivar-roaming' onClick={() => this.activarRoaming(2)}>{t('ConsumoReal.desactivar',)}</span>
                                    : <span className='boton-activar-roaming' onClick={() => this.activarRoaming(1)}>{t('ConsumoReal.activar',)}</span>
                                }

                            </div>
                            : ''}


                    </div> : ''}
                    <IonAlert
                        isOpen={this.state.alertOk}
                        cssClass='correcto'
                        header={t('ConsumoReal.roamingOk',)}
                        message={`<img src="${Ok}" alt="error" className="errorimg">`}
                        buttons={[{
                            text: [`${t('Aceptar.acep',)}`],
                            handler: () => {
                                window.location = '/Home';
                            }
                        }]}
                    />
                    <IonAlert
                        isOpen={this.state.alertKo}
                        cssClass='error'
                        header={t('ConsumoReal.roamingko',)}
                        message={`<img src="${Error}" alt="error" className="errorimg">`}
                        buttons={[{
                            text: [`${t('Aceptar.acep',)}`],
                            handler: () => {
                                window.location = '/Home';
                            }
                        }]}
                    />
                </div>
            )
        }

        return (
            <div className="consumo-home-global">
                {this.state.tarifaContratada != '' ? <div className='nombretarifa'><span><b>{t('Consumo.contratada',)}</b></span><p>{this.state.tarifaContratada}</p></div> : ''}
                {this.state.sinDatos && <span>Sin datos para esta línea</span>}
                <div className='graficos-dobles'>
                    <GraficoDonut
                        restante={this.state.restante}
                        total={this.state.total}
                        medida={this.state.medida}
                        color={'#fcba0c'}
                        iconos={'grey'}
                        css={'grafico-gb'}
                        responsive={true} />
                </div>

                <div className="contenedor-iconos" id="sms">
                    <button className="icono-linea-sms" onClick={() => this.sms()} >
                        <FontAwesomeIcon icon="sms" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-llama" onClick={() => this.llamadas()} id="llamadas">
                        <FontAwesomeIcon icon="phone-alt" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-gb" onClick={() => this.datos()} id="datos">
                        <div className={"iconos-consumo-home-dobles"} id="datos">
                            <FontAwesomeIcon icon="arrow-up" className={"iconos-gris"} id='datos' />
                            <FontAwesomeIcon icon="arrow-down" className={"iconos-gris"} id="datos" />
                        </div>

                    </button>

                </div>
                {process.env.REACT_APP_APP_NAME == 'TMOVIL' ? <div className='resumen-consumo-rayas'>
                    <div className='resumen-consumo-rayas-min'>
                        <span className='rayita-min'>{t('Consumo.llama',)}</span>
                        <span className='rayita-min-cantidad'>{this.state.minutos} {t('Consumo.minutos',)}</span>
                    </div>
                    <div className='raya-abajo'>

                    </div>
                    <div className='resumen-consumo-rayas-sms'>
                        <span className='rayita-min'>SMS</span>
                        <span className='rayita-min-cantidad'>{this.state.sms} </span>
                    </div>
                    {this.state.mostrarRoaming ?
                        <div className='activar-roaming'>
                            <span className='rayita-min'>{t('ConsumoReal.roaming',)}</span>
                            {this.state.estadoRoaming ? <div className='float-roaming'>
                                <div className="switch-button-roaming">
                                    <input
                                        type="checkbox"
                                        name="switchLabel"
                                        id="switchLabel"
                                        onClick={() => this.activarRoaming(2)}
                                        className="switch-button__checkbox-roaming" />
                                    <label htmlFor="switchLabel" className="switch-button__label-roaming" onChange={this.handleChange} />
                                </div>
                            </div>
                                : <div className='float-roaming'>
                                    <div className="switch-button-roaming-apa">
                                        <input
                                            type="checkbox"
                                            name="switchLabel"
                                            id="switchLabel"
                                            onClick={() => this.abrirModal()}
                                            className="switch-button__checkbox-roaming-apa" />
                                        <label htmlFor="switchLabel" className="switch-button__label-roaming-apa" onChange={this.handleChange} />
                                    </div>
                                </div>
                            }

                        </div>
                        : ''}

                </div> : ''}
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('ConsumoReal.roamingOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('ConsumoReal.roamingko',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]}
                />
                <Modal isOpen={this.state.modal} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('ConsumoReal.info5',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModal}>
                                <FontAwesomeIcon icon="times" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('ConsumoReal.info1',)}</p>
                        <p>{t('ConsumoReal.info2',)}</p>
                        <p>{t('ConsumoReal.info3',)}</p>
                        <p>{t('ConsumoReal.info4',)}</p>

                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <button className="btn-rojo-xl-tarifas" onClick={() => this.activarRoaming(1)}>
                            <span>{t('ConsumoReal.activar',)}</span>
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )


    }
}

const ConsumoRealXtraCom = withTranslation('common')(ConsumoRealXtraComTranslated)
export default ConsumoRealXtraCom;