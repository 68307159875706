import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import ConsumoRealAire from '../ConsumosReal/ConsumoRealAire';
import ConsumoRealXtraCom from '../ConsumosReal/ConsumoRealXtraCom';
import ConsumoVacio from '../ConsumosReal/ConsumoVacio';
import ConsumoRealPtv from '../ConsumosReal/ConsumoRealPtv';
import ConsumoRealLemon from '../ConsumosReal/ConsumoRealLemon';
let userConsumo;
let consumo1 = [];
class ConsumoActualTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        apiTipo: '',
        loading: true,
        form: {
            telefono: 'Seleccione un número'
        },
        sinDatos: false,
    };

    componentDidMount() {
        this.fetchData();

    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });
        userConsumo = undefined;

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });


            if (!Array.isArray(userConsumo)) {
                this.abrirAlert();
                this.setState({ loading: false, error: true });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    handleChange = async (e) => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
        });
        this.consultaConsumo();
    };


    consultaConsumo = async () => {
        if (this.state.form.telefono != '' && this.state.form.telefono != 'Num. Teléfono') {
            this.setState({ loading: true, error: null, apiTipo: '' });
            try {
                await api.Clientes.saberApiTipo(this.state.form.telefono).then(
                    ({ data }) => (consumo1 = data),
                );

                if (Number.isInteger(consumo1)) {
                    if (consumo1 != 1 && consumo1 != 2 && consumo1 != 48) {
                        consumo1 = 10;
                    }
                    this.setState({ loading: false, apiTipo: consumo1 });
                } else {
                    this.setState({ loading: false, error: null, sinDatos: true });
                }
            } catch (error) {
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    };

    renderSwitch(param) {
        switch (param) {
            case 1:
                return <ConsumoRealAire ddi={this.state.form.telefono} />;
            case 2:
                return <ConsumoRealXtraCom ddi={this.state.form.telefono} />;
            case 10:
                return <ConsumoRealPtv ddi={this.state.form.telefono} />;
            case 48:
                return <ConsumoRealLemon ddi={this.state.form.telefono} />;
            default:
                return <ConsumoVacio />;
        }
    }


    render() {
        const { t } = this.props;

        if (this.state.loading === true && !this.state.array) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }


        return (
            <div className="consumo-home-global">
                <div className="">
                    <form onSubmit={this.props.onSubmit}>
                        <div className="select-telefonos-air">
                            <select name="telefono" onChange={this.handleChange} className="select-tlf-air" value={this.state.form.telefono}>
                                <option key="0">{t('Consumo.numero',)}</option>
                                {this.state.datos.map((dato) =>
                                    <option key={dato} value={dato}>{dato}</option>
                                )}
                            </select>
                        </div>
                    </form>
                    {this.state.sinDatos == true ? <div className='sin-datos'><span >{t('Consumo.sinDatos',)}</span></div> : ''}
                    {this.renderSwitch(this.state.apiTipo)}
                </div>
            </div>
        )


    }
}

const ConsumoActual = withTranslation('common')(ConsumoActualTranslated)
export default ConsumoActual;